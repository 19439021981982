<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import { ASSETS_BASE_URL } from "../../../helpers/constants";
import ImmigrationRequestServices from "../../../services/ImmigrationRequestServices";
import Swal from "sweetalert2";

export default {
  emits: ["reloadDatas"],
  components: {
    lottie: Lottie,
    flatPickr,
  },
  props: ["currentSubscription"],
  setup() {
    const assetsBaseUrl = ASSETS_BASE_URL;
    const labels = {
      passport_copy: {
        fr: "Copie du passeport",
        en: "Passport copy",
      },
      digital_passport_photo: {
        fr: "Photo numérique du passeport",
        en: "Digital passport photo",
      },
      diploma_and_transcript_in_english: {
        fr: "Diplôme et relevé de notes en anglais",
        en: "Diploma and transcript in English",
      },
      criminal_record_check: {
        fr: "Vérification du casier judiciaire",
        en: "Criminal record check",
      },
      two_letters_of_recommendation: {
        fr: "Deux lettres de recommandation",
        en: "Two letters of recommendation",
      },
      study_plan: {
        fr: "Plan d'étude",
        en: "Study plan",
      },
      cv: {
        fr: "CV",
        en: "CV",
      },
      medical_certificate_with_stamp: {
        fr: "Certificat médical avec tampon",
        en: "Medical certificate with stamp",
      },
      bank_statement: {
        fr: "Relevé bancaire",
        en: "Bank statement",
      },
      family_information_form: {
        fr: "Formulaire d'informations familiales",
        en: "Family information form",
      },
      language_proficiency_certificates: {
        fr: "Certificats de compétence linguistique",
        en: "Language proficiency certificates",
      },
      study_gap_justification: {
        fr: "Justification de l'écart d'études",
        en: "Study gap justification",
      },
    };

    function getLabel(code, language = "fr") {
      if (code.startsWith("diploma_item")) return "Diplômes / relevés de notes";
      const label = labels[code ?? ""];
      return label ? label[language] : code;
    }

    return { getLabel, assetsBaseUrl };
  },
  data() {
    return {
      submitting: false,

      fields: {
        file: null,
        code: "",
      },

      rangeDateconfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      filterdate: null,
      filterdate1: null,
      filtervalue: "All",
      filtervalue1: "All",
      filtersearchQuery1: null,
      date: null,
      searchQuery: null,
      page: 1,
      perPage: 20,
      pages: [],
      defaultOptions: {
        animationData: animationData,
      },
    };
  },

  computed: {
    allTransactions() {
      return this.currentSubscription?.documents || [];
    },
    displayedPosts() {
      return this.paginate(this.allTransactions);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return this.getLabel(data.code).toLowerCase().includes(search);
        });
      } else if (this.filterdate !== null) {
        console.log(this.filterdate);
        if (this.filterdate !== null) {
          var date1 = this.filterdate.split(" to ")[0];
          var date2 = this.filterdate.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.updated_at) >= new Date(date1) &&
            new Date(data.updated_at) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.filtervalue !== null) {
        return this.displayedPosts.filter((data) => {
          console.log(this.filtervalue);
          if (data.status === this.filtervalue || this.filtervalue == "All") {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
  },

  watch: {
    allTransactions() {
      this.setPages();
    },
  },
  created() {
    this.initDatas();
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },

  methods: {
    SearchData() {
      this.searchQuery = this.filtersearchQuery1;
      this.filterdate = this.filterdate1;
      this.filtervalue = this.filtervalue1;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.allTransactions.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(allTransactions) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return allTransactions.slice(from, to);
    },
    async handleSendDocument() {
      if (!this.fields.file) return;

      this.$startLoading();
      const formData = new FormData();
      formData.append("file", this.fields.file);
      formData.append("code", this.fields.code);

      await ImmigrationRequestServices.sendDocument(
        this.currentSubscription.id,
        formData
      )
        .then(() => {
          this.$saSuccess("Votre document a été envoyé !");
        })
        .catch(() => {
          this.$saError("Erreur lors de l'envoie du document !");
        })
        .finally(() => {
          // this.$stopLoading();
          this.$emit("reloadDatas");
        });
      this.initDatas();
    },

    async handleConfirmAllDocuments() {
      Swal.fire({
        title:
          "Êtes-vous sûr de confirmer tous les documents de l'utilisater ?",
        text: "Cela mènera automatiquement à l'étape suivante !",
        icon: "warning",
        showCancelButton: true,
        // cancelButtonColor: "#f46a6a",
        // confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, confirmer !",
      }).then((result) => {
        if (result.value) {
          this.$startLoading();
          ImmigrationRequestServices.confirmAllDocuments(
            this.currentSubscription.id
          )
            .then(() => {
              this.$saSuccess("Tous les documents ont été validés !");
            })
            .catch(() => {
              this.$saError("Erreur lors de la validation des documents !");
            })
            .finally(() => {
              this.$emit("reloadDatas");
            });
        }
      });
    },

    async handleConfirmDocument($document) {
      Swal.fire({
        title:
          "Êtes-vous sûr de confirmer le document '" +
          this.getLabel($document.code) +
          "'?",
        // text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        // cancelButtonColor: "#f46a6a",
        // confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, confirmer !",
      }).then((result) => {
        if (result.value) {
          this.$startLoading();
          ImmigrationRequestServices.confirmDocument(
            this.currentSubscription.id,
            $document.id
          )
            .then(() => {
              this.$saSuccess("Document mis à jour avec succès !");
            })
            .catch(() => {
              this.$saError("Erreur lors de la mise à jour du document !");
            })
            .finally(() => {
              this.$emit("reloadDatas");
            });
        }
      });
    },

    async handleRejectDocument($document) {
      Swal.fire({
        title:
          "Êtes-vous sûr de rejetter le document '" +
          this.getLabel($document.code) +
          "'?",
        // text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        // cancelButtonColor: "#f46a6a",
        // confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, rejetter !",
      }).then((result) => {
        if (result.value) {
          this.$startLoading();

          ImmigrationRequestServices.rejectDocument(
            this.currentSubscription.id,
            $document.id
          )
            .then(() => {
              this.$saSuccess("Document mis à jour avec succès !");
            })
            .catch(() => {
              this.$saError("Erreur lors de la mise à jour du document !");
            })
            .finally(() => {
              // this.$stopLoading();
              this.$emit("reloadDatas");
            });
        }
      });
    },

    initDatas() {
      this.fields = {
        file: null,
        code: "",
      };
    },

    handleReset() {
      this.initDatas();
    },

    async handleSubmit() {
      this.$startLoading();
    },

    handleUploadFile($event) {
      if ($event.target.files?.length) {
        this.fields.file = $event.target.files[0];
      } else {
        this.fields.file = null;
      }
    },
  },
};
</script>

<template>
  <form
    @submit.prevent="handleSubmit"
    class="card"
    style="border: 1px solid gray; border-radius: 8px; padding: 20px"
  >
    <div>
      <b-row>
        <b-col lg="12">
          <div class="mb-3">
            <label class="form-label" for="des-sender_name-name-input">
              Document à envoyer
            </label>
            <select
              class="form-select"
              id="des-sender_name-name-input"
              required
              v-model="fields.code"
            >
              <option :value="'code_1_2'">
                {{ "Premier document" }}
              </option>
              <option :value="'code_1_2'">
                {{ "Deuxième document" }}
              </option>
            </select>
          </div>
        </b-col>

        <input type="file" @change="handleUploadFile" />
      </b-row>
    </div>
    <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end mt-3">
        <button
          type="button"
          class="btn btn-success"
          @click.prevent="handleSendDocument"
          :disabled="this.fields.file == null"
        >
          Envoyer le document
        </button>
        <button
          type="button"
          class="btn btn-soft-warning"
          :disabled="!isModified"
          @click.prevent="handleReset"
        >
          Annuler
        </button>
      </div>
    </div>
  </form>

  <div
    class="card"
    style="border: 1px solid gray; border-radius: 8px; padding: 20px"
  >
    <div class="col-lg-12">
      <div class="card" id="tasksList">
        <div class="card-header border-0">
          <div class="d-flex align-items-center">
            <h5 class="card-title mb-0 flex-grow-1">
              Documents pour <b>#{{ currentSubscription.code }}</b>
            </h5>
            <div class="flex-shrink-0">
              <button
                class="btn btn-info add-btn"
                @click="handleConfirmAllDocuments"
              >
                <i class="ri-check-line align-bottom me-1"></i> Valider tous les
                documents reçus
              </button>
            </div>
          </div>
        </div>
        <div class="card-body border border-dashed border-end-0 border-start-0">
          <form>
            <div class="row g-3">
              <div class="col-xxl-5 col-sm-12">
                <div class="search-box">
                  <input
                    type="text"
                    class="form-control search bg-light border-light"
                    placeholder="Search for tasks or something..."
                    v-model="filtersearchQuery1"
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <!--end col-->

              <div class="col-xxl-4 col-sm-4">
                <flat-pickr
                  v-model="filterdate1"
                  placeholder="Selectionner la date"
                  :config="rangeDateconfig"
                  class="form-control"
                ></flat-pickr>
              </div>
              <!--end col-->

              <!--end col-->
              <div class="col-xxl-3 col-sm-4">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  @click="SearchData"
                >
                  <i class="ri-equalizer-fill me-1 align-bottom"></i>
                  Filters
                </button>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end card-body-->
        <div class="card-body">
          <div class="table-responsive table-card mb-4">
            <table class="table align-middle table-nowrap mb-0">
              <thead class="table-light text-muted">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" class="text-left">Document</th>
                  <th scope="col" class="text-center">Mode</th>
                  <th scope="col" class="text-center">Statut</th>
                  <th scope="col" class="text-center">Format</th>
                  <th scope="col" class="text-center">Envoyé le</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody class="list form-check-all">
                <tr v-for="(doc, index) of resultQuery" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="text-left">
                    {{ getLabel(doc.code) }}
                  </td>

                  <td class="text-center">
                    <span
                      v-if="doc.mode == 'upload'"
                      class="badge bg-soft-info text-info"
                    >
                      Reçu
                    </span>
                    <span v-else class="badge bg-soft-dark text-dark">
                      Envoyé
                    </span>
                  </td>

                  <td class="text-center">
                    <span
                      v-if="doc.file == null"
                      class="badge bg-soft-dark text-dark"
                    >
                      Abscent
                    </span>
                    <span
                      v-else-if="doc.status == '0'"
                      class="badge bg-soft-warning text-warning"
                    >
                      En attente
                    </span>

                    <span
                      v-else-if="doc.status == '-1'"
                      class="badge bg-soft-danger text-danger"
                    >
                      Rejet
                    </span>

                    <span
                      v-else-if="doc.status == '1'"
                      class="badge bg-soft-success text-success"
                    >
                      Validé
                    </span>
                  </td>

                  <td class="text-center">
                    <span v-if="doc.file">
                      {{ doc.file.extname.toUpperCase() }}
                    </span>
                    <small v-if="doc.file">
                      ({{ (doc.file.size / 1024).toFixed(2) }}Ko)
                    </small>
                    <span v-else>--</span>
                  </td>

                  <td class="text-center">
                    {{
                      doc.file != null
                        ? new Date(doc.updated_at).toLocaleString()
                        : "Non reçu"
                    }}
                  </td>

                  <td class="text-center">
                    <div v-if="doc.file != null" class="dropdown">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-soft-secondary btn-sm btn-icon"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="ri-more-fill"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <a
                            class="dropdown-item"
                            :href="assetsBaseUrl + doc.file.url"
                            target="_blank"
                            download
                            ><i
                              class="ri-download-line me-2 align-bottom text-muted"
                            ></i>
                            Télécharger
                          </a>
                        </li>
                        <template v-if="doc.mode === 'upload'">
                          <li>
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              @click.prevent="handleConfirmDocument(doc)"
                              ><i
                                class="ri-check-line me-2 align-bottom text-muted"
                              ></i>
                              Confirmer
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              @click.prevent="handleRejectDocument(doc)"
                              ><i
                                class="ri-alert-line me-2 align-bottom text-muted"
                              ></i>
                              Rejetter
                            </a>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!--end table-->
            <div class="noresult" style="display: none">
              <div class="text-center">
                <lottie
                  colors="primary:#121331,secondary:#08a88a"
                  :options="defaultOptions"
                  :height="75"
                  :width="75"
                />
                <h5 class="mt-2">Sorry! No Result Found</h5>
                <p class="text-muted mb-0">
                  We've searched more than 200k+ tasks We did not find any tasks
                  for you search.
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <!--end card-body-->
      </div>
      <!--end card-->
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</template>
