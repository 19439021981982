<script>
import { CountTo } from "vue3-count-to";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import Lottie from "@/components/widgets/lottie.vue";

import ImmigrationRequestServices from "../../services/ImmigrationRequestServices";

import SMSSender from "./parts/sms-sender";
import DocumentList from "./parts/documents";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Détails de la souscription",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      fields: {
        amount: "",
        reason: "",
        paid_at: "",
      },
      title: "Informations sur la requête d'immigration",
      items: [
        {
          text: "Requêtes d'immigration",
          href: "/immigration-requests",
        },
        {
          text: "Détails de la souscrition",
          active: true,
        },
      ],
      date3: null,
      rangeDateconfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
        mode: "range",
      },
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
      },
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      filterdate: null,
      filterdate1: null,
      filtervalue: "All",
      filtervalue1: "All",
      filtersearchQuery1: null,
      date: null,
      allTransactions: [],
      searchQuery: null,
      page: 1,
      perPage: 8,
      pages: [],
      defaultOptions: {
        animationData: animationData,
      },
      defaultOptions1: {
        animationData: animationData1,
      },
      currentSubscription: { steps: [] },
    };
  },
  components: {
    CountTo,
    Layout,
    PageHeader,
    lottie: Lottie,
    // Multiselect,
    SMSSender,
    DocumentList,
    flatPickr,
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.allTransactions);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return data.reason.toLowerCase().includes(search);
        });
      } else if (this.filterdate !== null) {
        console.log(this.filterdate);
        if (this.filterdate !== null) {
          var date1 = this.filterdate.split(" to ")[0];
          var date2 = this.filterdate.split(" to ")[1];
        }
        return this.displayedPosts.filter((data) => {
          if (
            new Date(data.dueDate.slice(0, 12)) >= new Date(date1) &&
            new Date(data.dueDate.slice(0, 12)) <= new Date(date2)
          ) {
            return data;
          } else {
            return null;
          }
        });
      } else if (this.filtervalue !== null) {
        return this.displayedPosts.filter((data) => {
          console.log(this.filtervalue);
          if (data.status === this.filtervalue || this.filtervalue == "All") {
            return data;
          } else {
            return null;
          }
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    allTransactions() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  beforeMount() {
    this.getCurrentSubscription();
  },

  methods: {
    handleCreateTransaction() {
      this.$startLoading();
      ImmigrationRequestServices.createTransaction(
        this.$route.params.id,
        this.fields
      )
        .then((data) => {
          if (!data.error) {
            this.$saSuccess("Votre transaction a été créée avec succès.");

            this.fields = {
              reason: "",
              amount: null,
              paid_at: null,
            };
          }
        })
        .catch(() => {
          this.$saError("Erreur lors de la création de votre transaction.");
        })
        .finally(() => {
          this.getCurrentSubscription();
          // this.$stopLoading();
        });
    },
    getCurrentSubscription() {
      ImmigrationRequestServices.getById(this.$route.params.id)
        .then((data) => {
          this.currentSubscription = data.request;
          this.allTransactions = [];
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          data.request.transactions.forEach((row) => {
            var dd = new Date(row.created_at);
            row.dueDate =
              dd.getDate() +
              " " +
              monthNames[dd.getMonth()] +
              ", " +
              dd.getFullYear();

            // row.image_src = `@/assets/images/products/img-8.png`;
            // row.image_src = 'https://api-node.themesbrand.website/fileupload/users_bucket/' + row.img;
            this.allTransactions.push(row);
          });
        })
        .catch((er) => {
          console.log(er);
        });
    },
    SearchData() {
      this.searchQuery = this.filtersearchQuery1;
      this.filterdate = this.filterdate1;
      this.filtervalue = this.filtervalue1;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.allTransactions.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(allTransactions) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return allTransactions.slice(from, to);
    },
    async handleDefinePrice($step_id) {
      Swal.fire({
        title: "Entrez le montant à attribuer à l'étape",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        showLoaderOnConfirm: true,
        preConfirm: async (amount) => {
          await ImmigrationRequestServices.defineStepPrice(
            this.currentSubscription.id,
            $step_id,
            { amount: Number(amount) }
          )
            .then(() => {
              this.$saSuccess("Le prix a été défini avec succès !");
              // Swal.showValidationMessage(`La validation du dossier`);
            })
            .catch(() => {
              Swal.showValidationMessage(
                `Erreur lors de la mise à jour de l'étape !`
              );
            })
            .finally(() => {
              // this.$stopLoading();
              this.$emit("reloadDatas");
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: `${result.value.login}'s avatar`,
            imageUrl: result.value.avatar_url,
          });
        }
      });
    },
    async handleConfirmStep($step_id) {
      Swal.fire({
        title: "Êtes-vous sûr de confirmer la validation de l'étape en cours ?",
        // text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        // cancelButtonColor: "#f46a6a",
        // confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, confirmer !",
      }).then((result) => {
        if (result.value) {
          this.$startLoading();

          ImmigrationRequestServices.confirmStep(
            this.currentSubscription.id,
            $step_id
          )
            .then(() => {
              this.$saSuccess("Document mis à jour avec succès !");
            })
            .catch(() => {
              this.$saError("Erreur lors de la mise à jour du document !");
            })
            .finally(() => {
              // this.$stopLoading();
              this.$emit("reloadDatas");
            });
        }
      });
    },
  },
  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div
        class="col-xxl-4 col-sm-6 project-card"
        v-for="(item, index) of currentSubscription.steps"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <div class="p-3 mt-n3 mx-n3 bg-soft-info rounded-top">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h5 class="mb-0 fs-14">
                    <router-link
                      v-if="item.step_configs.is_payable"
                      :to="{
                        name: 'immigration-requests.steps.details',
                        params: { id: currentSubscription.id, stepId: item.id },
                      }"
                      class="text-dark"
                    >
                      {{ item.step_configs.title }}
                    </router-link>
                    <span v-else>
                      {{ item.step_configs.title }}
                    </span>
                  </h5>
                </div>
              </div>
            </div>

            <div class="py-3">
              <div class="row gy-3">
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Niveau</p>
                    <div :class="`badge badge-soft-info fs-12`">
                      {{ item.step_configs.level }} /
                      {{ currentSubscription.steps.length }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Statut</p>
                    <h5
                      :class="`badge badge-soft-${
                        currentSubscription.level == item.step_configs.level
                          ? 'warning'
                          : item.step_configs.level > currentSubscription.level
                          ? 'info'
                          : 'success'
                      } fs-12`"
                    >
                      {{
                        currentSubscription.level == item.step_configs.level
                          ? "En cours"
                          : item.step_configs.level > currentSubscription.level
                          ? "En attente"
                          : "Validé"
                      }}
                    </h5>
                  </div>
                </div>
              </div>

              <div v-if="item.step_configs.is_payable" class="row gy-3">
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">À payer</p>
                    <div class="fs-12">{{ item.total_price }} FCFA</div>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Restant</p>
                    <h5 class="fs-12">
                      {{ item.total_price - item.current_balance }} FCFA
                    </h5>
                  </div>
                </div>

                <div
                  v-if="item.step_configs.level == currentSubscription.level"
                  class="d-flex justify-content-between gx-3"
                >
                  <button
                    @click.prevent="handleConfirmStep(item.id)"
                    class="me-1 w-100 btn btn-success"
                  >
                    Confirmer cette étape
                  </button>
                  <button
                    v-if="item.step_configs.is_payable && item.step_configs.amount == 0"
                    @click.prevent="handleDefinePrice(item.id)"
                    class="ms-1 w-100 btn btn-primary"
                  >
                    Définir le prix
                  </button>
                </div>
              </div>

              <div v-else class="row gy-3 mt-1">
                <div
                  v-if="item.step_configs.level == currentSubscription.level"
                  class="d-flex justify-content-between gx-3"
                >
                  <button
                    @click.prevent="handleConfirmStep(item.id)"
                    class="me-1 w-100 btn btn-success"
                  >
                    Confirmer cette étape
                  </button>
                </div>

                <div class="d-none badge badge-soft-secondary py-3 fs-14">
                  Pas de paiement
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Pays d'immigration</p>
                <h2 class="mt-4 ff-secondary fw-semibold">Chine</h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info rounded-circle fs-4">
                    <i class="ri-ticket-2-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Montant versé</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to
                    :startVal="0"
                    :endVal="
                      currentSubscription.steps.reduce(
                        (total, item) => total + item.current_balance,
                        0
                      )
                    "
                    :duration="3000"
                  ></count-to>
                  FCFA
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-warning rounded-circle fs-4">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
      </div>
      <!--end col-->
      <div class="col-xxl-4 col-sm-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Reste à verser</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to
                    :startVal="0"
                    :endVal="
                      2500000 -
                      currentSubscription.steps.reduce(
                        (total, item) => total + item.current_balance,
                        0
                      )
                    "
                    :duration="3000"
                  ></count-to>
                  FCFA
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-success rounded-circle fs-4">
                    <i class="mdi mdi-timer-sand"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">
                Transactions pour <b>#{{ currentSubscription.code }}</b>
              </h5>
            </div>
          </div>
          <div
            class="card-body border border-dashed border-end-0 border-start-0"
          >
            <form>
              <div class="row g-3">
                <div class="col-xxl-5 col-sm-12">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search bg-light border-light"
                      placeholder="Search for tasks or something..."
                      v-model="filtersearchQuery1"
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->

                <div class="col-xxl-4 col-sm-4">
                  <flat-pickr
                    v-model="filterdate1"
                    placeholder="Selectionner la date"
                    :config="rangeDateconfig"
                    class="form-control"
                  ></flat-pickr>
                </div>
                <!--end col-->

                <!--end col-->
                <div class="col-xxl-3 col-sm-4">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="SearchData"
                  >
                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                    Filters
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <!--end card-body-->
          <div class="card-body">
            <div class="table-responsive table-card mb-4">
              <table class="table align-middle table-nowrap mb-0">
                <thead class="table-light text-muted">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Raison</th>
                    <th scope="col">Créé le</th>
                  </tr>
                </thead>
                <tbody class="list form-check-all">
                  <tr v-for="(item, index) of resultQuery" :key="index">
                    <td>#{{ index + 1 }}</td>
                    <td>{{ item.amount }} FCFA</td>
                    <td>
                      {{ item.reason }}
                    </td>
                    <td>{{ item.dueDate }}</td>
                  </tr>
                </tbody>
              </table>

              <!--end table-->
              <div class="noresult" style="display: none">
                <div class="text-center">
                  <lottie
                    colors="primary:#121331,secondary:#08a88a"
                    :options="defaultOptions"
                    :height="75"
                    :width="75"
                  />
                  <h5 class="mt-2">Sorry! No Result Found</h5>
                  <p class="text-muted mb-0">...</p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-3">
              <div class="pagination-wrap hstack gap-2">
                <a
                  class="page-item pagination-prev disabled"
                  href="#"
                  v-if="page != 1"
                  @click="page--"
                >
                  Précédent
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li
                    :class="{
                      active: pageNumber == page,
                      disabled: pageNumber == '...',
                    }"
                    v-for="(pageNumber, index) in pages.slice(
                      page - 1,
                      page + 5
                    )"
                    :key="index"
                    @click="page = pageNumber"
                  >
                    <a class="page" href="#">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a
                  class="page-item pagination-next"
                  href="#"
                  @click="page++"
                  v-if="page < pages.length"
                >
                  Suivant
                </a>
              </div>
            </div>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">
            Informations sur la procédure
          </h5>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Nom complet</h5>
                <p class="card-text">
                  {{ currentSubscription.last_name }}
                  {{ currentSubscription.first_name }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Sexe</h5>
                <p class="card-text">
                  {{ currentSubscription.gender === "M" ? "Homme" : "Femme" }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Date de naissance</h5>
                <p class="card-text">{{ currentSubscription.birth_date }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Ville de naissance</h5>
                <p class="card-text">{{ currentSubscription.birth_city }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Pays de naissance</h5>
                <p class="card-text">{{ currentSubscription.birth_country }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Adresse</h5>
                <p class="card-text">{{ currentSubscription.address }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Pays de résidence</h5>
                <p class="card-text">
                  {{ currentSubscription.residence_country }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">État / Région de résidence</h5>
                <p class="card-text">
                  {{ currentSubscription.residence_state }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Ville de résidence</h5>
                <p class="card-text">
                  {{ currentSubscription.residence_city }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Numéro de téléphone</h5>
                <p class="card-text">{{ currentSubscription.phone_number }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Adresse e-mail</h5>
                <p class="card-text">{{ currentSubscription.contact_email }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Niveau</h5>
                <p class="card-text">{{ currentSubscription.level }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Cursus</h5>
                <p class="card-text">{{ currentSubscription.cursus }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Code</h5>
                <p class="card-text">{{ currentSubscription.code }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">
            Envoyer un document à l'utilisateur
          </h5>
        </div>
      </div>
      <div class="card-body">
        <DocumentList
          :currentSubscription="currentSubscription"
          @reloadDatas="getCurrentSubscription"
        />
      </div>
    </div>

    <div class="card card-animate">
      <div class="card-header border-0">
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0 flex-grow-1">Envoyer un SMS manuel</h5>
        </div>
      </div>
      <div class="card-body">
        <SMSSender
          :currentSubscription="currentSubscription"
          @reloadDatas="getCurrentSubscription"
        />
      </div>
    </div>
  </Layout>
</template>
